import React from "react"
import "./foodtech.css"
import Footer from "../components/footer"
import SEO from "../components/seo"
import logo from "../../static/foodtech/foodtech_logo.png"
import v60 from "../../static/foodtech/v60.png"
import delivery from "../../static/foodtech/delivery.png"
import square from "../../static/foodtech/square.png"
import skill_circle from "../../static/foodtech/skill_circle.png"
import skill_square from "../../static/foodtech/skill_square.png"
import skill_double from "../../static/foodtech/skill_double.png"
import fact_1 from "../../static/foodtech/fact1.png"
import fact_2 from "../../static/foodtech/fact2.png"
import ContactFood from "../components/contactFood"
import IconPhoto from "../../static/foodtech/icon_photo.png"
import IconDelivery from "../../static/foodtech/icon_delivery.png"
import IconSocial from "../../static/foodtech/icon_social.png"
import IconGuide from "../../static/foodtech/icon_guide.png"
import IconSEO from "../../static/foodtech/icon_seo.png"
import IconSite from "../../static/foodtech/icon_site.png"
import IconQR from "../../static/foodtech/icon_qr.png"
import IconConsult from "../../static/foodtech/icon_consult.png"

export default () => {
  return (
    <div>
      <SEO
        title="Foodtech"
        description="Nos expertises digitales et F&B au service
de votre entreprise."
        lang="fr"
      />
      <nav>
        <a href="https://webpanda.ch/fr">
          <img src={logo} id="logo" />
        </a>
      </nav>
      <div id="header-container">
        <div id="left-side">
          <img src={v60} id="v60" />
          <h3>
            <span className="underline-food">
              Focalisez-vous sur votre restaurant,
            </span>{" "}
            <br />
            on s'occupe du reste
          </h3>
        </div>
        <div id="right-side">
          <img src={delivery} id="delivery" />
        </div>
      </div>
      <div id="experts">
        <div id="experts-left">
          <img src={square} id="square" />
        </div>
        <div id="experts-right">
          <h3>
            Nos expertises{" "}
            <span className="underline-bold">digitales et F&B</span> au service{" "}
            <br />
            de votre entreprise.
          </h3>
          <p>
            Nous savons que le monde du digital, et notamment les réseaux
            sociaux, est un enjeu majeur pour l'expansion de votre restaurant.{" "}
            <br className="phone-break" />
            <br className="phone-break" />
            C’est pourquoi nous vous proposons de nous occuper intégralement
            d’intégrer et de faire vivre votre restaurant sur ces différentes
            plateformes.
          </p>
        </div>
      </div>
      <div id="skillset">
        <div className="card-skills">
          <img src={skill_circle} className="skills" />
          <h4>Gestion des réseaux sociaux</h4>
          <div className="separator-skill" />
          <p>
            Boostez la visibilité et le traffic sur vos pages Facebook et
            Instagram.
          </p>
        </div>
        <div className="card-skills">
          <img src={skill_square} className="skills" />
          <h4>Management des platformes F&B</h4>
          <div className="separator-skill" />
          <p>
            Optimisez votre image et vos ventes via Tripadvisor, LaFourchette,
            UberEats, Google et d'autres
          </p>
        </div>
        <div className="card-skills">
          <img src={skill_double} className="skills" />
          <h4>Création de sites web</h4>
          <div className="separator-skill" />
          <p>
            Créez un site moderne à l'image de votre concept permettant la
            réservation et la commande en ligne.
          </p>
        </div>
      </div>
      <div className="fact-a">
        <div className="fact-left">
          <img src={fact_1} />
        </div>
        <div className="fact-right">
          <h3 className="fact_punch">
            <span className="fact-high">83% de vos clients</span> ont vérifié
            sur internet avant de réserver.
          </h3>
        </div>
      </div>
      <div className="fact-b">
        <div className="fact-left">
          <h3 className="fact_punch">
            Bénéficiez d'un accompagnement{" "}
            <span className="fact-high">personalisé</span> et{" "}
            <span className="fact-high">accessible.</span>
          </h3>
        </div>
        <div className="fact-right">
          <img src={fact_2} />
        </div>
      </div>
      <div id="prestations">
        <h3>Nos prestations</h3>
        <div className="prestations-line">
          <div className="prestations-card">
            <img src={IconPhoto} />
            <h4>Photo</h4>
            <p>
              Shooting Photo de votre restaurant (plats, salle, exterieur).
              Ameliorez votre image en ligne
            </p>
            <div className="separator-card" />
          </div>
          <div className="prestations-card">
            <img src={IconDelivery} />
            <h4>Delivery</h4>
            <p>
              Augmentez votre chiffre d'affaire sur les plateformes de livraison
              grâce à notre expertise
            </p>
            <div className="separator-card" />
          </div>
          <div className="prestations-card">
            <img src={IconSocial} />
            <h4>Réseaux sociaux</h4>
            <p>
              Acquerrez et fidélisez une nouvelle clientèle grâce à notre
              accompagnement
            </p>
            <div className="separator-card" />
          </div>
          <div className="prestations-card">
            <img src={IconGuide} />
            <h4>Guide en ligne</h4>
            <p>
              Boostez votre notoriété sur Google business, Tripadvisor,
              LaFourchette et d'autres
            </p>
            <div className="separator-card" />
          </div>
        </div>
        <div className="prestations-line">
          <div className="prestations-card">
            <img src={IconSEO} />
            <h4>SEO</h4>
            <p>
              Optimisez le référencement de votre site web et générez plus de
              réservations
            </p>
            <div className="separator-card" />
          </div>
          <div className="prestations-card">
            <img src={IconSite} />
            <h4>Site web</h4>
            <p>Misez sur un site internet qui boost vos ventes</p>
            <div className="separator-card" />
          </div>
          <div className="prestations-card">
            <img src={IconQR} />
            <h4>Menu QR code</h4>
            <p>Digitalisez votre menu pour respecter les mesures sanitaires</p>
            <div className="separator-card" />
          </div>
          <div className="prestations-card">
            <img src={IconConsult} />
            <h4>Conseil digital</h4>
            <p>
              Beneficiez également de notre expertise dans d'autres domaines
              (logo, graphisme, app, filtre insta)
            </p>
            <div className="separator-card" />
          </div>
        </div>
      </div>
      <div id="foodtech-background">
        <ContactFood lang="fr" />
      </div>
      <Footer lang="fr" />
    </div>
  )
}
