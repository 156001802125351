import React from "react"
import "./contactFood.css"
import LeftSectionTitle from "../components/leftSectionTitle"
import Dict from "../langs/dict"

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.dict = new Dict(props.lang)
    this.state = {
      validation: "",
    }
  }

  clearState = () => {
    this.setState({})
  }

  writeValidation = text => {
    this.setState({
      validation: text,
    })
  }

  encode = data => {
    const formData = new FormData()
    for (const key of Object.keys(data)) {
      formData.append(key, data[key])
    }
    return formData
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    // More advanced validation?
    if (typeof this.state.name === "undefined") {
      this.writeValidation(this.dict.getTrans("form_validation_name"))
      return
    }
    if (typeof this.state.email === "undefined") {
      this.writeValidation(this.dict.getTrans("form_validation_email"))
      return
    }
    if (typeof this.state.message === "undefined") {
      this.writeValidation(this.dict.getTrans("form_validation_message"))
      return
    }

    fetch("/", {
      method: "POST",
      body: this.encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => {
        this.writeValidation(this.dict.getTrans("form_success"))
      })
      .catch(error => this.writeValidation(this.dict.getTrans("form_fail")))
  }

  render() {
    return (
      <div id="contact-holder">
        <LeftSectionTitle
          title={this.dict.getTrans("contact_title")}
          subTitle={this.dict.getTrans("contact_text")}
        />
        <div id="contact-form">
          <div id="form-holder">
            <form
              name="foodtech"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
            >
              <input type="hidden" name="form-name" value="foodtech" />
              <p hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name="bot-field" onChange={this.handleChange} />
                </label>
              </p>
              <label htmlFor="name-input" className="hidden-input">
                Name:
              </label>
              <input
                type="text"
                placeholder={this.dict.getTrans("contact_placeholder_name")}
                name="name"
                onChange={this.handleChange}
                id="name-input"
              />
              <label htmlFor="email-input" className="hidden-input">
                Email:
              </label>
              <input
                type="text"
                placeholder={this.dict.getTrans("contact_placeholder_email")}
                name="email"
                onChange={this.handleChange}
                id="email-input"
              />
              <label htmlFor="message-input" className="hidden-input">
                Message:
              </label>
              <textarea
                placeholder={this.dict.getTrans("contact_placeholder_message")}
                name="message"
                onChange={this.handleChange}
                id="message-input"
              />
              <input
                type="submit"
                className="contact-us"
                id="form-button"
                value={this.dict.getTrans("contact_button_text")}
              />
              <p id="validation">{this.state.validation}</p>
            </form>
          </div>
          <div id="contact-info">
            <h3>foodtech@webpanda.ch</h3>
            <h3>{this.dict.getTrans("contact_phone")}</h3>
          </div>
        </div>
      </div>
    )
  }
}
